import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, } from "@angular/core";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
// import * as moment from 'moment';
import { Validators } from "@angular/forms";
import { NgbDatepickerConfig, NgbModal, } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment-timezone";
import { ImageCropperComponent } from "../image-cropper/image-cropper.component";
import * as dayjs from "dayjs";
var EditApplicationComponent = /** @class */ (function () {
    function EditApplicationComponent(window, localStorage, app, router, rest, route, translateService, formDataService, formBuilder, _messageService, modalService, config, spinner, imageCompressor, el) {
        this.window = window;
        this.localStorage = localStorage;
        this.app = app;
        this.router = router;
        this.rest = rest;
        this.route = route;
        this.translateService = translateService;
        this.formDataService = formDataService;
        this.formBuilder = formBuilder;
        this._messageService = _messageService;
        this.modalService = modalService;
        this.spinner = spinner;
        this.imageCompressor = imageCompressor;
        this.el = el;
        this.NIB3 = environment.featureNIB3;
        this.expDate = [];
        this.dateBirth = [];
        this.imageChangedEvent = "";
        this.imageChangedEventPass = "";
        this.imageChangedEventPassCover = "";
        this.croppedImage = "";
        this.croppedImagePass = "";
        this.croppedImagePassCover = "";
        this.cropperReady = false;
        this.cropperReadyPass = false;
        this.isIntendedError = false;
        this.isPhotoBigSize = false;
        this.isPassportBigSize = false;
        this.isCoverPassportBigSize = false;
        this.acceptTerm = false;
        this.isExpiredDate = false;
        this.selectedPhoneCode = "USA +1";
        this.isRequestCountry = false;
        this.hotel_doc_big_size = false;
        this.flight_doc_big_size = false;
        this.third_visa_doc_big_size = false;
        this.guarantor_visa_doc_big_size = false;
        this.hotel_warning_message = "";
        this.flight_warning_message = "";
        this.third_visa_warning_message = "";
        this.guarantor_warning_message = "";
        this.submitted = false;
        this.photo_count = 0;
        this.passport_count = 0;
        this.isPassportCoverSmallSize = false;
        this.days = 0;
        this.except_message = "";
        this.next_2month = { year: 0, month: 0, day: 0 };
        this.next_7day = { year: 0, month: 0, day: 0 };
        this.today = { year: 0, month: 0, day: 0 };
        this.citizens = "";
        this.raceSection = false;
        this.proofFileName = "";
        this.proofFileCount = 0;
        this.imgResultBeforeCompression = "";
        this.imgResultAfterCompression = "";
        this.setFormLocalStorage = {
            photoBase64: "",
            passportBase64: "",
            photoImg: "",
            passportImg: "",
            photoImgUrl: "",
            passportImgUrl: "",
            lastName: "",
            firstName: "",
            gender: "",
            dateBirth: { year: 0, month: 0, day: 0 },
            genderSelect: "",
            countryBirth: null,
            telephone: "",
            occupation: "",
            residentialAddress: "",
            stateProvince: "",
            countryCity: null,
            passportNum: "",
            dateIssue: { year: 0, month: 0, day: 0 },
            dateExpire: { year: 0, month: 0, day: 0 },
            countryPassport: null,
            intendDate: "",
            vehicle: "",
            purposeTrip: null,
            addressDuringVisit: "",
            nextDestination: "",
            checkExpDate: dayjs().format("YYYY-MM-DD"),
        };
        this.enableNext = false;
        config.minDate = { year: 1900, month: 1, day: 1 };
    }
    EditApplicationComponent.prototype.onRaceChange = function (event) {
        this.person.race = Number(event.value);
        if (event.value == 0) {
            this.personRace = 0;
            this.raceSection = false;
            this.person.race = 0;
            this.person.family_side = "";
            this.person.family_member_names = "";
            this.person.family_relation = "";
            this.enableNext = true;
        }
        else {
            this.personRace = 304;
            this.raceSection = true;
            this.person.race = 304;
            this.enableNext = false;
        }
    };
    EditApplicationComponent.prototype.proofFileChangeEvent = function (e) {
        var _this = this;
        this.proofFileCount = 1;
        var file = e.target.files[0];
        this.proofFileName = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _this.proofFile = reader.result.toString();
            _this.proofFilePreview = reader.result.toString();
            _this.person.proof_file_preview = reader.result.toString();
        };
    };
    EditApplicationComponent.prototype.removeProofFile = function () {
        this.proofFile = "";
        this.proofFilePreview = "";
        this.proofFileName = "";
        this.proofFileCount = 0;
    };
    EditApplicationComponent.prototype.onUploadProofFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("prooffile", this.proofFile);
                        if (!this.proofFile) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.rest.uploadProofFileService(formData)];
                    case 1:
                        result = _a.sent();
                        if (result.status == "success") {
                            this.proofFile = "";
                            this.proofFilePreview = "";
                            this.proofFileName = "";
                            this.proofFileCount = 0;
                            this.enableNext = true;
                            this.person.proof_of_laotian = result.name;
                            this.personForm.value.proof_of_laotian = result.name;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    EditApplicationComponent.prototype.fileChangeEvent = function (event) {
        //   const file = event.target.files[0];
        //   const img = new Image();
        //   img.src = window.URL.createObjectURL(file);
        //   const width = img.naturalWidth;
        //   const height = img.naturalHeight;
        //   if ( width < 300 && height < 300 ) {
        //     this.isPhotoSmallSize = true;
        //     return;
        //  }
        if (event.target.files[0].size > 10485760) {
            this.isPhotoBigSize = true;
            return;
        }
        else {
            this.isPhotoBigSize = false;
            this.imageChangedEvent = event;
            this.photo_count = 1;
        }
    };
    EditApplicationComponent.prototype.fileChangeEventPass = function (event) {
        //   const file = event.target.files[0];
        //   const img = new Image();
        //   img.src = window.URL.createObjectURL(file);
        //   const width = img.naturalWidth;
        //   const height = img.naturalHeight;
        //   if ( width < 300 && height < 300 ) {
        //     this.isPassportSmallSize = true;
        //     return;
        //  }
        if (event.target.files[0].size > 10485760) {
            this.isPhotoBigSize = true;
            this.isPassportBigSize = true;
            return;
        }
        else {
            this.isPassportBigSize = false;
            this.imageChangedEventPass = event;
            this.passport_count = 1;
        }
    };
    EditApplicationComponent.prototype.fileChangeEventPassCover = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.isPassportCoverBigSize = true;
            return;
        }
        else {
            this.isPassportCoverBigSize = false;
            this.imageChangedEventPassCover = event;
            this.passport_cover_count = 1;
        }
    };
    EditApplicationComponent.prototype.cropperReadyLog = function () {
        console.log("Cropper ready");
    };
    EditApplicationComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
        // this.person.imgPhoto = event.base64;
        // console.log(event);
    };
    EditApplicationComponent.prototype.imageCroppedPass = function (event) {
        this.croppedImagePass = event.base64;
        // this.person.imgPass = event.base64;
        // console.log(event);
    };
    EditApplicationComponent.prototype.imageCroppedPassCover = function (event) {
        this.croppedImagePassCover = event.base64;
    };
    EditApplicationComponent.prototype.imageLoaded = function () {
        this.cropperReady = true;
    };
    EditApplicationComponent.prototype.imageLoadedPass = function () {
        this.cropperReadyPass = true;
    };
    EditApplicationComponent.prototype.imageLoadedPassCover = function () {
        this.cropperReadyPassCover = true;
    };
    EditApplicationComponent.prototype.loadImageFailed = function () {
        // console.log('Load failed');
    };
    EditApplicationComponent.prototype.loadImageFailedPass = function () {
        // console.log('Load failed');
    };
    EditApplicationComponent.prototype.loadImageFailedPassCover = function () {
        // console.log('Load failed');
    };
    EditApplicationComponent.prototype.rotateLeft = function (img) {
        console.log(this.photoCropper);
        console.log(this[img + "Cropper"]);
        this[img + "Cropper"].rotateLeft();
    };
    EditApplicationComponent.prototype.rotateRight = function (img) {
        this[img + "Cropper"].rotateRight();
    };
    EditApplicationComponent.prototype.flipHorizontal = function (img) {
        this[img + "Cropper"].flipHorizontal();
    };
    EditApplicationComponent.prototype.flipVertical = function (img) {
        this[img + "Cropper"].flipVertical();
    };
    EditApplicationComponent.prototype.verifyToken = function (credential) {
        var _this = this;
        this.rest.verifyToken(credential).subscribe(function (result) {
            if (!result["auth"] &&
                result.message === "Failed to authenticate token.") {
                _this.router.navigate(["/apply"], { replaceUrl: true });
                localStorage.removeItem(environment.localAuthenInfo);
            }
            if (!result["auth"] && result.message === "TokenExpired") {
                _this.router.navigate(["/apply"], { replaceUrl: true });
                localStorage.removeItem(environment.localAuthenInfo);
            }
            if (!result["responseStatus"]) {
                localStorage.removeItem(environment.localAuthenInfo);
                _this.router.navigate(["/apply"], { replaceUrl: true });
            }
            else {
                localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
                _this.router.navigate(["/application"]);
            }
        }, function (error) {
            console.error(error);
            localStorage.removeItem(environment.localAuthenInfo);
            _this.router.navigate(["/apply"], { replaceUrl: true });
        });
    };
    EditApplicationComponent.prototype.getCountryList = function () {
        var _this = this;
        this.rest.getCountry().subscribe(function (result) {
            // this.mDataArrayCountry = result.country;
            _this.mDataArrayCountry = result;
        });
    };
    EditApplicationComponent.prototype.getCountryBirthList = function () {
        var _this = this;
        this.rest.getCountryBirth().subscribe(function (result) {
            // this.mDataArrayCountryBirth = result.country;
            _this.mDataArrayCountryBirth = result;
        });
    };
    EditApplicationComponent.prototype.getVisaTypeList = function () {
        var _this = this;
        this.rest.getVisaType().subscribe(function (result) {
            _this.mDataArrayVisaType = result;
        });
    };
    EditApplicationComponent.prototype.getPortEntryList = function () {
        var _this = this;
        this.rest.getPortEntry().subscribe(function (result) {
            _this.mDataArrayPortEntry = result;
        });
    };
    EditApplicationComponent.prototype.getPurposeList = function () {
        var _this = this;
        this.rest.getPurpose().subscribe(function (result) {
            _this.mPurpose = result;
        });
    };
    EditApplicationComponent.prototype.getCallingCodeList = function () {
        var _this = this;
        this.rest.getCallingCode().subscribe(function (result) {
            _this.mDataArrayCallingCode = result;
        });
    };
    EditApplicationComponent.prototype.getExceptMessage = function () {
        var _this = this;
        this.rest.getExceptMessage().subscribe(function (result) {
            setTimeout(function () {
                _this.except_message = result["except_message"];
            });
        });
    };
    EditApplicationComponent.prototype.getApplicationInfo = function (application_id) {
        var _this = this;
        this.rest.getApplicationInfo(application_id).subscribe(function (rs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var split, _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!environment.production)
                            console.log("Get Application Info: ", rs);
                        if (!(rs.status !== "notfound")) return [3 /*break*/, 3];
                        this.find_status = true;
                        this.person.application_id = rs.real_application_id;
                        this.person.hotel_doc = rs.hotel_document;
                        this.person.flight_doc = rs.flight_document;
                        this.person.guarantor_doc = rs.guarantor_document;
                        this.person.third_visa_doc = rs.third_visa_document;
                        this.person.bank_statement_doc = rs.bank_statement;
                        this.person.imgPhotoName = rs.photo;
                        this.person.imgPassName = rs.passport;
                        if (("" + rs.photo_name).includes("data:image/png;base64,") ||
                            ("" + rs.passport_name).includes("data:image/png;base64,")) {
                            this.person.imgPhoto = rs.photo_name;
                            this.person.imgPass = rs.passport_name;
                            this.custImage.imgPhoto = rs.photo_name;
                            this.custImage.imgPass = rs.passport_name;
                            this.setFormLocalStorage.photoImgUrl = environment.node_image_url + "/photo/" + rs.photo.substring(0, 8) + "/" + rs.photo;
                            this.setFormLocalStorage.passportImgUrl = environment.node_image_url + "/passport/" + rs.passport.substring(0, 8) + "/" + rs.passport;
                        }
                        else {
                            this.person.imgPhoto = "data:image/png;base64," + rs.photo_name;
                            this.person.imgPass = "data:image/png;base64," + rs.passport_name;
                            this.custImage.imgPhoto = "data:image/png;base64," + rs.photo_name;
                            this.custImage.imgPass = "data:image/png;base64," + rs.passport_name;
                        }
                        this.person.firstName = rs.first_name;
                        this.person.lastName = rs.last_name;
                        this.person.gender = rs.gender;
                        this.person.DOB = {
                            year: Number(moment(rs.dob).tz("Asia/Vientiane").format("YYYY")),
                            month: Number(moment(rs.dob).tz("Asia/Vientiane").format("MM")),
                            day: Number(moment(rs.dob).tz("Asia/Vientiane").format("DD")),
                        };
                        this.person.countryBirth = rs.country_birth_id;
                        this.person.tel = rs.telephone;
                        this.person.occupation = rs.occupation;
                        this.person.resAddress = rs.residential_address;
                        this.person.province = rs.state_province;
                        this.person.countryCity = rs.country_of_city;
                        this.person.passportNumber = rs.passport_number;
                        this.person.issueDate = {
                            year: Number(moment(rs.issue_date).tz("Asia/Vientiane").format("YYYY")),
                            month: Number(moment(rs.issue_date).tz("Asia/Vientiane").format("MM")),
                            day: Number(moment(rs.issue_date).tz("Asia/Vientiane").format("DD")),
                        };
                        this.person.expiredDate = {
                            year: Number(moment(rs.expired_date).tz("Asia/Vientiane").format("YYYY")),
                            month: Number(moment(rs.expired_date).tz("Asia/Vientiane").format("MM")),
                            day: Number(moment(rs.expired_date).tz("Asia/Vientiane").format("DD")),
                        };
                        this.person.countryOfPassport = rs.country_of_passport;
                        this.person.visaType = rs.visa_type_id;
                        this.person.portOfEntry = rs.port_of_entry;
                        this.person.intendedDate = {
                            year: Number(moment(rs.intended_date).tz("Asia/Vientiane").format("YYYY")),
                            month: Number(moment(rs.intended_date).tz("Asia/Vientiane").format("MM")),
                            day: Number(moment(rs.intended_date).tz("Asia/Vientiane").format("DD")),
                        };
                        this.next_7day.year = Number(moment(rs.intended_date).tz("Asia/Vientiane").format("YYYY"));
                        this.next_7day.month = Number(moment(rs.intended_date).tz("Asia/Vientiane").format("MM"));
                        this.next_7day.day = Number(moment(rs.intended_date).tz("Asia/Vientiane").format("DD"));
                        console.log("next 7 day :", (this.next_7day.day = Number(moment(rs.intended_date).tz("Asia/Vientiane").format("DD"))));
                        this.localStorage.setItem("previousData", JSON.stringify(this.setFormLocalStorage));
                        this.person.vehicle = rs.vehicle;
                        this.person.purposeOfTrip = rs.purpos_of_trip;
                        this.person.addressDuringVisit = rs.address_during_visit;
                        this.person.next_destination = rs.next_destination;
                        this.person.selectedGender = rs.gender;
                        this.person.selectedCountryBirth = rs.country_birth;
                        this.person.selectedCountryCity = rs.country_city;
                        this.person.selectedCountryOfPassport = rs.country_passport;
                        this.person.selectedvisaType = rs.visa_type_name;
                        this.person.selectedportOfEntry = rs.port_of_entry_name;
                        this.person.selectedPurpose = rs.purpose_name;
                        this.person.first_action_date = rs.first_action_date;
                        this.personRace = rs.race;
                        this.person.race = rs.race;
                        this.person.family_side = rs.family_side ? rs.family_side : "";
                        this.person.family_relation = rs.family_relation
                            ? rs.family_relation
                            : "";
                        this.person.family_member_names = rs.family_member_names
                            ? rs.family_member_names
                            : "";
                        this.person.proof_of_laotian = rs.proof_of_laotian
                            ? rs.proof_of_laotian
                            : "";
                        if (rs.proof_of_laotian) {
                            split = ("" + rs.proof_of_laotian).split("/").pop();
                            if (split == null || split == "null") {
                                this.proofFilePreview = null;
                                this.person.proof_file_preview = "";
                                this.proofFileCount = 0;
                            }
                            else {
                                this.proofFileCount = 1;
                            }
                            this.proofFilePreview = rs.proof_of_laotian;
                            this.person.proof_file_preview = rs.proof_of_laotian;
                        }
                        // console.log(rs.country_of_city);
                        _a = this.showRequestDocument;
                        return [4 /*yield*/, rs.country_of_city];
                    case 1:
                        // console.log(rs.country_of_city);
                        _a.apply(this, [_c.sent()]);
                        _b = this.showRequestDocument;
                        return [4 /*yield*/, rs.country_of_passport];
                    case 2:
                        _b.apply(this, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 3:
                        this.router.navigate(["/apply"]);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    EditApplicationComponent.prototype.deletePerson = function (application_id) {
        var id = application_id[0].application_id;
        this.rest.deletePerson(id).subscribe(function (result) {
            // console.log(result);
        });
    };
    EditApplicationComponent.prototype.readURL = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                if (event.target.getAttribute("name") === "inputPassportFile") {
                    _this.person.imgPass = e.target.result;
                }
                else if (event.target.getAttribute("name") === "inputPhotoFile") {
                    _this.person.imgPhoto = e.target.result;
                }
            };
            reader.readAsDataURL(file);
            // reader.readAsDataURL(event.target.files[0]);
        }
    };
    EditApplicationComponent.prototype.save = function (form) {
        if (this.person.imgPassName == null) {
            return false;
        }
        if (this.person.imgPhotoName == null) {
            return false;
        }
        if (this.person.countryCity === null) {
            return false;
        }
        if (this.person.countryOfPassport === null) {
            return false;
        }
        if (!form.valid) {
            return false;
        }
        if (this.personRace == 304) {
            return false;
        }
        console.log(this.person);
        this.formDataService.setPerson(this.person);
        return true;
    };
    EditApplicationComponent.prototype.goToNext = function (form) {
        if (this.save(form)) {
            // Navigate to the work page
            this.router.navigate(["/review"]);
        }
    };
    EditApplicationComponent.prototype.IntendedOnChange = function (event) {
        this.intendedDate = event;
        this.validateIntendedDate(event);
    };
    EditApplicationComponent.prototype.validateIntendedDate = function (event) {
        if (this.expiredDate && this.intendedDate) {
            var inten = new Date(this.intendedDate.year, this.intendedDate.month - 1, this.intendedDate.day);
            var ex = new Date(this.expiredDate.year, this.expiredDate.month - 1, this.expiredDate.day);
            var intended = moment(inten);
            var expired = moment(ex);
            var diff = expired.diff(intended, "months");
            // console.log('inten :' + inten);
            // console.log('ex :' + ex);
            // console.log('diff month more 6 or not:' + diff);
            if (diff >= 6) {
                this.isIntendedError = false;
            }
            else {
                if (this.app.SelectedLang == "en") {
                    this.intentedMessage =
                        "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
                }
                else if (this.app.SelectedLang == "cn") {
                    this.intentedMessage =
                        "护照有效期不得少于6个月，将从入境老挝之日起开始计算。入境日期不得晚于申请电子签证后60天。建议您至少在出发前 5 天提出申请。";
                }
                else if (this.app.SelectedLang == "fr") {
                    this.intentedMessage =
                        "The passport should have minimum validity of 6 months from the date of arrival in Laos. Your expected date of entry should be within 60 days as of the date of an eVisa application. You are suggested to apply at least 5 days before your departure.";
                }
                this.isIntendedError = true;
                return;
            }
        }
        if (this.intendedDate) {
            var inten = new Date(this.intendedDate.year, this.intendedDate.month - 1, this.intendedDate.day);
            var intended = moment(inten);
            var now = new Date();
            var checkintenDate = intended.diff(now, "days") + 1;
            // console.log('check if intended not more 7 day ' + checkintenDate);
            if (checkintenDate < 5) {
                // this.intentedMessage = 'Intended Date of Entry must be 5 days after the day of applying.';
                // this.isIntendedError = true;
                return;
            }
            else {
                this.isIntendedError = false;
            }
        }
    };
    EditApplicationComponent.prototype.ExpiredOnChange = function (event) {
        this.person.expiredDate = event;
        this.expiredDate = event;
        if (this.expiredDate) {
            var curent_date = new Date();
            var ex = new Date(this.expiredDate.year, this.expiredDate.month - 1, this.expiredDate.day);
            var now = moment(curent_date);
            var expired = moment(ex);
            var diff = expired.diff(now, "days");
            if (diff <= 0) {
                if (this.expiredDate.day > 0) {
                    this.isExpiredDate = true;
                }
            }
            else {
                this.isExpiredDate = false;
                this.validateIntendedDate(event);
            }
        }
    };
    EditApplicationComponent.prototype.IssueOnChange = function (event) {
        this.issueDate = event;
        if (this.issueDate && this.expiredDate) {
            var curent_date = new Date();
            var ex = new Date(this.expiredDate.year + 10, this.expiredDate.month - 1, this.expiredDate.day);
            var now = moment(curent_date);
            var expired = moment(ex);
            var diff = expired.diff(now, "days");
            if (diff <= 0) {
                if (this.expiredDate.day > 0) {
                    this.isExpiredDate = true;
                }
            }
            else {
                this.isExpiredDate = false;
                this.validateIntendedDate(event);
            }
        }
    };
    EditApplicationComponent.prototype.onCountryBirthChange = function (selectedId) {
        var s = this.mDataArrayCountryBirth.find(function (x) { return x.country_id == selectedId; });
        this.person.selectedCountryBirth = s["country_name"];
    };
    EditApplicationComponent.prototype.onPurposeChange = function (selectedId) {
        var s = this.mPurpose.find(function (x) { return x.purpose_id == selectedId; });
        this.person.selectedPurpose = s["purpose_name"];
    };
    EditApplicationComponent.prototype.onGenderChange = function (event) {
        var selectedOptions = event.target["options"];
        var selectedIndex = selectedOptions.selectedIndex;
        var selectElementText = selectedOptions[selectedIndex].text;
        this.person.selectedGender = selectElementText;
    };
    EditApplicationComponent.prototype.onCountryOfCityChange = function (selectedId, event) {
        console.log("Country City:", selectedId);
        if (selectedId !== "") {
            var s = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            this.person.selectedCountryCity = s["country_name"];
            this.person.selectedCountryOfPassport = s["country_name"];
            this.citizens = s["citizens"];
            this.days = s["stay_day"];
            // this.personForm.patchValue({
            //   countryOfPassport: selectedId
            // });
            var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            if (country.is_except == 1) {
                event.srcElement.blur();
                event.preventDefault();
                this.openLg(this.content);
            }
            if (country.request_attach === 1) {
                this.person.is_request_doc = true;
            }
            else if (country.request_attach == 0) {
                this.person.is_request_doc = false;
            }
        }
        else {
            this.person.is_request_doc = false;
        }
    };
    EditApplicationComponent.prototype.openLg = function (content) {
        this.modalService.open(content, { size: "lg", centered: true });
    };
    EditApplicationComponent.prototype.onCountryOfPassportChange = function (selectedId, event) {
        console.log("COuntry ID:", selectedId);
        if (selectedId !== "") {
            if (this.selectedCountry) {
                this.selectedCountry = selectedId;
            }
            var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            this.person.selectedCountryOfPassport = country["country_name"];
            if (country.is_except === 1) {
                event.srcElement.blur();
                event.preventDefault();
                this.openLg(this.content);
            }
            if (country.request_attach === 1) {
                this.person.is_request_doc = true;
            }
            else if (country.request_attach == 0) {
                this.person.is_request_doc = false;
            }
        }
        else {
            this.person.is_request_doc = false;
        }
    };
    EditApplicationComponent.prototype.showRequestDocument = function (selectedId) {
        if (selectedId) {
            if (this.mDataArrayCountry.length > 0) {
                var s = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
                this.person.selectedCountryCity = s["country_name"];
                this.person.selectedCountryOfPassport = s["country_name"];
                this.personForm.patchValue({
                    countryOfPassport: selectedId,
                });
                var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
                if (country.is_except == 1) {
                }
                if (country.request_attach === 1) {
                    this.person.is_request_doc = true;
                }
                else if (country.request_attach == 0) {
                    this.person.is_request_doc = false;
                }
            }
        }
        else {
            this.person.is_request_doc = false;
        }
    };
    EditApplicationComponent.prototype.onVisaTypeChange = function (selectedId) {
        var s = this.mDataArrayVisaType.find(function (x) { return x.visa_type_id == selectedId; });
        this.person.selectedvisaType = s["visa_type_name"];
    };
    EditApplicationComponent.prototype.onPortOfEntryChange = function (selectedId) {
        var s = this.mDataArrayPortEntry.find(function (x) { return x.port_of_entry_id == selectedId; });
        this.person.selectedportOfEntry = s["port_of_entry_name"];
    };
    EditApplicationComponent.prototype.onSubmit = function (form) {
        if (this.personForm.invalid) {
            this.personForm.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        }
        // console.log(form.value);
        // console.log(this.personForm.controls['expiredDate'].valid);
        this.submitted = true;
        if (!this.person.countryCity) {
            console.log("Country city is not selected", this.person.countryCity);
            return;
        }
        if (!this.person.countryOfPassport) {
            console.log("Country passport is not selected", this.person.countryOfPassport);
            return;
        }
        // console.log(this.person);
        if (this.person.is_request_doc === true) {
            if (this.person.hotel_doc === "" ||
                this.person.hotel_doc === null ||
                this.hotel_doc_big_size === true) {
                return;
            }
            if (this.person.flight_doc === "" ||
                this.person.flight_doc === null ||
                this.flight_doc_big_size === true) {
                return;
            }
            if (this.person.third_visa_doc === "" ||
                this.person.third_visa_doc === null ||
                this.third_visa_doc_big_size === true) {
                return;
            }
            if (this.person.hotel_doc === "" ||
                this.person.hotel_doc === null ||
                this.guarantor_visa_doc_big_size === true) {
                return;
            }
            if (this.person.bank_statement_doc === "" ||
                this.person.bank_statement_doc === null ||
                this.bank_statement_doc_big_size === true) {
                return;
            }
        }
        console.log(this.personForm);
        // console.log('form log: ' + this.personForm.valid);
        // if (this.personForm.valid) {
        if (this.save(form)) {
            this.localStorage.setItem("previousData", JSON.stringify(this.setFormLocalStorage));
            // Navigate to the work page
            this.router.navigate(["/review"]);
        }
        // }
    };
    EditApplicationComponent.prototype.checkAccept = function () {
        this.acceptTerm = !this.acceptTerm;
    };
    EditApplicationComponent.prototype.onUploadPhotoImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // this.person.imgPhoto = this.croppedImage;
                        this.custImage.imgPhoto = this.croppedImage;
                        formData = new FormData();
                        formData.append("upload_file_photo", this.custImage.imgPhoto);
                        formData.append("version", "2");
                        return [4 /*yield*/, this.rest.uploadPhotoFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (Array.isArray(result)) {
                                        this.person.imgPhotoName = result[0].name;
                                        this.setFormLocalStorage.photoImgUrl = environment.node_image_url + "/photo/" + dayjs().format("YYYYMMDD") + "/" + result[0].name;
                                    }
                                    else {
                                        this.person.imgPhotoName = result.name;
                                        this.setFormLocalStorage.photoImgUrl = environment.node_image_url + "/photo/" + dayjs().format("YYYYMMDD") + "/" + result.name;
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditApplicationComponent.prototype.onUploadPassportImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event, formData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        setTimeout(function () { return _this.spinner.hide(); }, 3000);
                        // this.person.imgPass = this.croppedImagePass;
                        this.custImage.imgPass = this.croppedImagePass;
                        event = this.imageChangedEventPass;
                        formData = new FormData();
                        formData.append("upload_file_passport", this.custImage.imgPass);
                        return [4 /*yield*/, this.rest.uploadPassportFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.person.imgPassName = result.name;
                                    this.setFormLocalStorage.passportImgUrl = environment.node_image_url + "/passport/" + dayjs().format("YYYYMMDD") + "/" + result.name;
                                    console.log(result);
                                    if (result.mrz !== null) {
                                        if (result.mrz.status !== "failed") {
                                            // this.mrzData = result.mrz.data.passport;
                                            // this.person.lastName = this.mrzData.lastName;
                                            // this.person.firstName = this.mrzData.firstName;
                                            // this.person.passportNumber = this.mrzData.passportNumber;
                                            // this.person.gender = `${this.mrzData.sex}`.toLowerCase();
                                            // this.expDate = result.mrz.data.passport.expirationDate.split("-");
                                            // this.dateBirth = this.mrzData.birthDate.split("-");
                                            // this.person.countryCity = this.mrzData.countryOfCitizenshipId;
                                            // this.person.countryOfPassport = this.mrzData.countryOfPassportId;
                                            if (this.mrzData.lastName)
                                                this.person.lastName = this.mrzData.lastName;
                                            if (this.mrzData.firstName)
                                                this.person.firstName = this.mrzData.firstName;
                                            if (this.mrzData.passportNumber)
                                                this.person.passportNumber = this.mrzData.passportNumber;
                                            if (this.mrzData.sex)
                                                this.person.gender = ("" + this.mrzData.sex).toLowerCase();
                                            if (result.mrz.data.passport)
                                                this.expDate = result.mrz.data.passport.expirationDate.split("-");
                                            if (this.mrzData.birthDate)
                                                this.dateBirth = this.mrzData.birthDate.split("-");
                                            if (this.mrzData.countryOfCitizenshipId)
                                                this.person.countryCity = this.mrzData.countryOfCitizenshipId;
                                            if (this.mrzData.countryOfPassportId)
                                                this.person.countryOfPassport = this.mrzData.countryOfPassportId;
                                            // this.person.countryCity = null;
                                            // this.person.countryOfPassport = null;
                                            this.ExpiredOnChange({
                                                year: parseInt(this.expDate[0]),
                                                month: parseInt(this.expDate[1]),
                                                day: parseInt(this.expDate[2]),
                                            });
                                            // this.DateBirthOnChange({ year: parseInt(this.dateBirth[0]), month: parseInt(this.dateBirth[1]), day: parseInt(this.dateBirth[2]) })
                                            this.onCountryOfCityChange(this.mrzData.countryOfCitizenshipId || this.person.countryCity, "");
                                            this.onCountryOfPassportChange(this.mrzData.countryOfPassportId || this.person.countryOfPassport, "");
                                        }
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditApplicationComponent.prototype.onUploadPassportCoverImage = function () {
        var _this = this;
        // this.person.imgPass = this.croppedImagePass;
        this.custImage.imgCover = this.croppedImagePassCover;
        var event = this.imageChangedEventPassCover;
        var formData = new FormData();
        formData.append("upload_file_passport_cover", this.custImage.imgCover);
        this.rest.uploadPassportCoverFile(formData).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.person;
                        return [4 /*yield*/, result.name];
                    case 1:
                        _a.imgCoverName = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    EditApplicationComponent.prototype.onFileHotelChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.hotel_doc_big_size = true;
        }
        else {
            this.hotel_doc_big_size = false;
            this.selectedFile = event.target.files[0];
            this.person.hotel_doc = event.target.files[0].name;
            // console.log(this.person.hotel_doc);
            this.onUpload();
        }
    };
    EditApplicationComponent.prototype.onFileFlightChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.flight_doc_big_size = true;
        }
        else {
            this.flight_doc_big_size = false;
            this.selectedFileFlight = event.target.files[0];
            this.onUploadFlight();
        }
    };
    EditApplicationComponent.prototype.onFileThirdVisaChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.third_visa_doc_big_size = true;
        }
        else {
            this.third_visa_doc_big_size = false;
            this.selectedFileThirdVisa = event.target.files[0];
            this.person.third_visa_doc = event.target.files[0].name;
            this.onUploadThirdVisa();
        }
    };
    EditApplicationComponent.prototype.onFileGuarantorInputUploadChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.guarantor_visa_doc_big_size = true;
        }
        else {
            this.guarantor_visa_doc_big_size = false;
            this.selectedFileGuarantor = event.target.files[0];
            this.person.guarantor_doc = event.target.files[0].name;
            this.onUploadGuarantor();
        }
    };
    EditApplicationComponent.prototype.onFileBankStatementChanged = function (event) {
        if (event.target.files[0].size > 2097152) {
            this.bank_statement_doc_big_size = true;
        }
        else {
            this.bank_statement_doc_big_size = false;
            this.selectedFileBankStatement = event.target.files[0];
            this.person.bank_statement_doc = event.target.files[0].name;
            this.onUploadBankStatement();
        }
    };
    EditApplicationComponent.prototype.onUpload = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("hotel_file", this.selectedFile, this.selectedFile.name);
        this.rest.uploadHoteldoc(uploadData).subscribe(function (result) {
            _this.person.hotel_doc = result.name;
        });
    };
    EditApplicationComponent.prototype.onUploadFlight = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("flight_file", this.selectedFileFlight, this.selectedFileFlight.name);
        this.rest.uploadFlightdoc(uploadData).subscribe(function (result) {
            _this.person.flight_doc = result.name;
        });
    };
    EditApplicationComponent.prototype.onUploadThirdVisa = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("third_visa_file", this.selectedFileThirdVisa, this.selectedFileThirdVisa.name);
        this.rest.uploadThirdVisadoc(uploadData).subscribe(function (result) {
            _this.person.third_visa_doc = result.name;
        });
    };
    EditApplicationComponent.prototype.onUploadGuarantor = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("guarantor_file", this.selectedFileGuarantor, this.selectedFileGuarantor.name);
        this.rest.uploadGuarantordoc(uploadData).subscribe(function (result) {
            _this.person.guarantor_doc = result.name;
        });
    };
    EditApplicationComponent.prototype.onUploadBankStatement = function () {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append("bank_statement_file", this.selectedFileBankStatement, this.selectedFileBankStatement.name);
        this.rest.uploadBankStatementdoc(uploadData).subscribe(function (result) {
            _this.person.bank_statement_doc = result.name;
        });
    };
    Object.defineProperty(EditApplicationComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.personForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EditApplicationComponent.prototype.ngAfterViewInit = function () {
        var authInfo = JSON.parse(this.localStorage.getItem(environment.localAuthenInfo));
        if (authInfo) {
            this.person.token = authInfo.token;
            this.getCountryList();
            this.getCountryBirthList();
            this.getVisaTypeList();
            this.getPortEntryList();
            this.getCallingCodeList();
            this.getPurposeList();
            this.getExceptMessage();
        }
        else {
            this.router.navigate(["apply"]);
        }
    };
    EditApplicationComponent.prototype.ngOnInit = function () {
        // this.next_7day.year = Number(moment().add(5, 'd').format('YYYY'));
        // this.next_7day.month = Number(moment().add(5, 'd').format('MM'));
        // this.next_7day.day = Number(moment().add(5, 'd').format('DD'));
        var _this = this;
        this.today.year = Number(moment().format("YYYY"));
        this.today.month = Number(moment().format("MM"));
        this.today.day = Number(moment().format("DD"));
        this.next_2month.year = Number(moment().add(60, "d").format("YYYY"));
        this.next_2month.month = Number(moment().add(60, "d").format("MM"));
        this.next_2month.day = Number(moment().add(60, "d").format("DD"));
        this.route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.getApplicationInfo(params["id"]);
                return [2 /*return*/];
            });
        }); });
        this.personForm = this.formBuilder.group({
            firstName: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z ,.]*")],
            ],
            lastName: ["", [Validators.pattern("[a-zA-Z ,.]*")]],
            gender: ["", [Validators.required]],
            DOB: ["", [Validators.required]],
            countryBirth: ["", [Validators.required]],
            tel: [
                "",
                [Validators.required, Validators.pattern("^[0-9+-s]{6}[0-9+-s]+$")],
            ],
            occupation: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            resAddress: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,.#]*")],
            ],
            province: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            countryCity: ["", [Validators.required]],
            next_destination: [" ", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
            passportNumber: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
            ],
            issueDate: ["", [Validators.required]],
            expiredDate: ["", [Validators.required]],
            countryOfPassport: ["", [Validators.required]],
            visaType: ["1"],
            portOfEntry: [""],
            intendedDate: ["", [Validators.required]],
            vehicle: ["", [Validators.pattern("[a-zA-Z0-9 ,]*")]],
            purposeOfTrip: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            addressDuringVisit: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9 ,]*")],
            ],
            myfileFlight: ["", []],
            myfileHotel: ["", []],
            ThirdCountryVisa: ["", []],
            guarantor: ["", []],
            bank_statement: ["", []],
            race: [0, []],
            family_side: ["", []],
            family_member_names: ["", []],
            family_relation: ["", []],
        });
        this.person = this.formDataService.getFormData();
        this.custImage = this.formDataService.getFormData();
        this.person.currentActiveForm = "1";
        console.log(this.person);
        $(document).ready(function () {
            $("#warningUploadPassport").hide();
            $("#warningUploadPhoto").hide();
            $("#btnContinue").click(function () {
                if ($("#inputPassportFile")[0].files.length === 0) {
                    $("#warningUploadPassport").show();
                }
                if ($("#inputPhotoFile")[0].files.length === 0) {
                    $("#warningUploadPhoto").show();
                }
            });
            $("#btnshowModalPassport").click(function () {
                $("#warningUploadPassport").hide();
            });
            $("#btnshowModalPhoto").click(function () {
                $("#warningUploadPhoto").hide();
            });
        });
    };
    EditApplicationComponent.prototype.scrollToFirstInvalidControl = function () {
        var firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
        // firstInvalidControl.focus(); //without smooth behavior
        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth",
        });
    };
    EditApplicationComponent.prototype.getTopOffset = function (controlEl) {
        var labelOffset = 150;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    };
    EditApplicationComponent.prototype.onActivate = function (event) {
        this.window.scroll(0, 0);
        // or document.body.scrollTop = 0;
        // or document.querySelector('body').scrollTo(0,0)
    };
    return EditApplicationComponent;
}());
export { EditApplicationComponent };
