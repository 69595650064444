import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { NgbDatepickerConfig, } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { saveAs } from "file-saver";
var CheckStatusComponent = /** @class */ (function () {
    function CheckStatusComponent(localStorage, config, router, rest, seo, app, spinner) {
        this.localStorage = localStorage;
        this.router = router;
        this.rest = rest;
        this.seo = seo;
        this.app = app;
        this.spinner = spinner;
        this.suspenseDownload = false;
        this.notfound = false;
        this.processing = false;
        this.rejected = false;
        this.approved = false;
        this.banned = false;
        this.unpaid = false;
        this.can_edit = false;
        this.model_verify = {};
        this.node_static_url = environment.node_static_url;
        this.invoice_id = 0;
        this.total = 0;
        this.countTotal = 0;
        this.isEmptyCart = false;
        this.timeLeft = 0;
        this.clicked = false;
        this.my_uuid = "";
        this.seo.addMetaTags();
        config.minDate = { year: 1900, month: 1, day: 1 };
    }
    CheckStatusComponent.prototype.ngOnInit = function () {
        this.getImageFromService();
        this.getMaintenanceService();
    };
    CheckStatusComponent.prototype.createImageFromBlob = function (image) {
        var _this = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            _this.captchaImage = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    };
    CheckStatusComponent.prototype.getImageFromService = function () {
        var _this = this;
        this.isImageLoading = true;
        this.rest.getCaptchaImage().subscribe(function (data) {
            _this.createImageFromBlob(data);
            _this.isImageLoading = false;
        }, function (error) {
            _this.isImageLoading = false;
            console.log(error);
        });
    };
    CheckStatusComponent.prototype.getMaintenanceService = function () {
        // this.rest.getMaintenance().subscribe(
        //   (data) => {
        //     this.maintenance = data.maintenance;
        //     this.date_maintenance = data.date_maintenance;
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );
    };
    CheckStatusComponent.prototype.reloadCaptcha = function () {
        this.getImageFromService();
    };
    CheckStatusComponent.prototype.onSubmit = function (form) {
        var _this = this;
        this.spinner.show();
        this.processing = false;
        this.notfound = false;
        this.rejected = false;
        this.banned = false;
        this.clicked = false;
        if (form.valid) {
            this.rest
                .verifyCaptchaCheckStatus(this.model_verify)
                .subscribe(function (result) {
                _this.spinner.hide();
                if (result.responseStatus === false) {
                    _this.isNotSuccess = true;
                    _this.message = "Security code is wrong!";
                    _this.getImageFromService();
                }
                else {
                    _this.getImageFromService();
                    _this.localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
                    _this.rest.checkStatus(_this.model_verify).subscribe(function (rs) {
                        _this.have_result = true;
                        _this.isSuccess = true;
                        console.log("Result Status form Back End:", rs);
                        if (rs.status === "notfound") {
                            _this.notfound = true;
                            _this.localStorage.removeItem(environment.localAuthenInfo);
                        }
                        else if (rs.status === "processing") {
                            _this.processing = true;
                            _this.created_date = rs.created_date;
                            if (rs.updated_date) {
                                _this.updated_date = rs.updated_date;
                            }
                            _this.localStorage.removeItem(environment.localAuthenInfo);
                        }
                        else if (rs.status === "rejected") {
                            _this.rejected = true;
                            _this.action_status = rs.action_status;
                            _this.reasons = rs.reason_rejected;
                            _this.created_date = rs.created_date;
                            if (rs.updated_date) {
                                _this.updated_date = rs.updated_date;
                            }
                            _this.comment = rs.comment;
                            _this.my_uuid = rs.id;
                            if (rs.can_edit == "yes") {
                                _this.can_edit = true;
                            }
                            else {
                                _this.can_edit = false;
                            }
                        }
                        else if (rs.status === "approved") {
                            _this.approved = true;
                            _this.created_date = rs.created_date;
                            if (rs.updated_date) {
                                _this.updated_date = rs.updated_date;
                            }
                            _this.my_uuid = rs.id;
                        }
                        else if (rs.status === "banned") {
                            _this.banned = true;
                            _this.reasons = rs.reason_rejected;
                            _this.created_date = rs.created_date;
                            if (rs.updated_date) {
                                _this.updated_date = rs.updated_date;
                            }
                            _this.comment = rs.comment;
                            _this.localStorage.removeItem(environment.localAuthenInfo);
                        }
                        else if (rs.status === "unpaid") {
                            _this.unpaid = true;
                            _this.invoice_id = rs.invoice_id;
                            _this.created_date = rs.created_date;
                            if (rs.updated_date) {
                                _this.updated_date = rs.updated_date;
                            }
                            _this.selected_payment_method = "";
                            _this.my_uuid = rs.id;
                        }
                        else if (rs.status === "cancelled") {
                            _this.cancelled = true;
                            _this.localStorage.removeItem(environment.localAuthenInfo);
                        }
                        if (_this.app.SelectedLang == "en") {
                            _this.status_message = rs.message;
                        }
                        else if (_this.app.SelectedLang == "cn") {
                            _this.status_message = rs.message_cn;
                        }
                        else if (_this.app.SelectedLang == "fr") {
                            _this.status_message = rs.message_fr;
                        }
                    });
                }
            });
        }
    };
    CheckStatusComponent.prototype.editApplication = function () {
        this.router.navigate([
            "/edit-application/" + this.model_verify.application_id,
        ]);
    };
    CheckStatusComponent.prototype.back = function () {
        this.have_result = false;
        this.model_verify.verify_code = "";
        this.clicked = false;
    };
    // DownLoad PDF
    CheckStatusComponent.prototype.downloadPdf = function () {
        var _this = this;
        var app_id = this.model_verify.application_id;
        var passport_number = this.model_verify.passport_number;
        var verify_code = this.model_verify.verify_code;
        var data_download = {
            application_id: app_id,
            passport_number: passport_number,
            verify_code: verify_code,
            id: this.my_uuid,
        };
        this.spinner.show();
        this.rest.downloadPDF(data_download).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var filename;
            return tslib_1.__generator(this, function (_a) {
                filename = app_id;
                saveAs(result, this.my_uuid + ".pdf");
                this.spinner.hide();
                return [2 /*return*/];
            });
        }); });
    };
    return CheckStatusComponent;
}());
export { CheckStatusComponent };
