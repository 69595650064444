import * as tslib_1 from "tslib";
import { HttpHeaders, } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ng-toolkit/universal";
import * as i2 from "@angular/common/http";
var RestService = /** @class */ (function () {
    function RestService(localStorage, http) {
        this.localStorage = localStorage;
        this.http = http;
        this.baseUrl = environment.node_static_url;
        this.paymentUrl = environment.node_payment_url + "/v1";
        this.apiUrl = this.baseUrl + "/v1";
        this.apiUrl2 = this.baseUrl + "/v2";
        this.apiVerifyCaptchaUrl = "" + this.baseUrl;
        this.headers = new HttpHeaders({ "Content-Type": "application/json" });
        this.detectFaceUrl = this.apiUrl + "/detect_face";
        this.uploadPhotoImageUrl = this.apiUrl + "/upload_photo_file";
        this.uploadPassportImageUrl = this.apiUrl + "/upload_passport_file";
        this.uploadPassportCoverImageUrl = this.apiUrl + "/upload_passport_cover_file";
        this.uploadHoteldocUrl = this.apiUrl + "/upload_hotel_file";
        this.ocrMRZUrl = "http://10.2.100.131:30107/evisa-ocr-api/v1/mrz";
    }
    // get maintenance url
    RestService.prototype.getMaintenance = function () {
        var getCaptchaUrl = this.apiUrl + "/maintenance";
        return this.http
            .get(getCaptchaUrl)
            .catch(this.handleError);
    };
    // get image to page apply
    RestService.prototype.getCaptchaImage = function () {
        var getCaptchaUrl = this.apiUrl + "/secure/captchasrc";
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    };
    // get image to page contact
    RestService.prototype.getCaptchaImageContact = function () {
        var getCaptchaUrl = this.apiUrl + "/contact/captchasrc";
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    };
    // get image to page contact
    RestService.prototype.getCaptchaImageReApply = function () {
        var getCaptchaUrl = this.apiUrl + "/re_apply/captchasrc";
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    };
    // get image to page contact
    RestService.prototype.getCaptchaImageCheckStatus = function () {
        var getCaptchaUrl = this.apiUrl + "/check_status/captchasrc";
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    };
    // get token to page apply
    RestService.prototype.getToken = function () {
        var getGenerateTokenUrl = this.apiUrl + "/secure/generateToken";
        return this.http.get(getGenerateTokenUrl);
    };
    // verify captcha in page apply
    RestService.prototype.verifyCaptcha = function (credential) {
        var verifyUrl = (environment.featureSSID ? this.apiUrl2 : this.apiUrl) + "/secure/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    // verify captcha in page apply
    RestService.prototype.verifyPin = function (credential) {
        var verifyUrl = (environment.featureSSID ? this.apiUrl2 : this.apiUrl) + "/secure/verify_pin";
        return this.http.post(verifyUrl, credential);
    };
    // verify captcha in page apply
    RestService.prototype.verifyCaptchaContact = function (credential) {
        var verifyUrl = this.apiUrl + "/contact/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    RestService.prototype.verifyTokenService = function (credential) {
        var verifyUrl = this.apiUrl + "/contact/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    RestService.prototype.verifyToken = function (credential) {
        var verifyUrl = (environment.featureSSID ? this.apiUrl2 : this.apiUrl) + "/secure/verify_email";
        return this.http
            .post(verifyUrl, { token: credential })
            .catch(this.handleError);
    };
    // verify captcha in page apply
    RestService.prototype.verifyCaptchaReApply = function (credential) {
        var verifyUrl = this.apiUrl + "/re_apply/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    // verify captcha in page apply
    RestService.prototype.verifyCaptchaCheckStatus = function (credential) {
        var verifyUrl = this.apiUrl + "/check_status/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    RestService.prototype.verifyRetrieveApplication = function (credential) {
        var verifyUrl = this.apiUrl + "/retrieve_application/captchaVerify";
        return this.http.post(verifyUrl, credential);
    };
    RestService.prototype.handleError = function (error) {
        return Observable.throw("My handle err: " + error.message || "Server Error");
    };
    // Component data
    RestService.prototype.getCountry = function () {
        var getContryUrl = this.apiUrl + "/component/getCountry";
        return this.http.get(getContryUrl);
    };
    RestService.prototype.getCountryPriceCalculator = function () {
        var getContryUrl = this.apiUrl + "/component/getCountryPriceCalculator";
        return this.http.get(getContryUrl);
    };
    RestService.prototype.getCountryBirth = function () {
        var getContryUrl = this.apiUrl + "/component/getCountryBirth";
        return this.http.get(getContryUrl);
    };
    RestService.prototype.getFAQ = function (language) {
        var url = this.apiUrl + "/component/getFAQ/" + language;
        return this.http.get(url).catch(this.handleError);
    };
    RestService.prototype.getVisaType = function () {
        var getVisaTypeUrl = this.apiUrl + "/component/getVisaType";
        return this.http.get(getVisaTypeUrl);
    };
    RestService.prototype.getPortEntry = function () {
        var getPortEntryUrl = this.apiUrl + "/component/getPortEntry";
        return this.http.get(getPortEntryUrl);
    };
    RestService.prototype.getPurpose = function () {
        var url = this.apiUrl + "/component/getPurpose";
        return this.http.get(url);
    };
    RestService.prototype.getCallingCode = function () {
        var getCallingCodeUrl = this.apiUrl + "/component/getCallingCode";
        return this.http.get(getCallingCodeUrl);
    };
    RestService.prototype.detectFace = function (data) {
        return this.http.post(this.detectFaceUrl, data);
    };
    RestService.prototype.uploadPhotoFile = function (data) {
        return this.http.post(this.uploadPhotoImageUrl, data);
    };
    RestService.prototype.uploadPassportFile = function (data) {
        return this.http.post(this.uploadPassportImageUrl, data);
    };
    RestService.prototype.uploadPassportCoverFile = function (data) {
        return this.http.post(this.uploadPassportCoverImageUrl, data);
    };
    RestService.prototype.submitApplicationForm = function (credential) {
        var postUrl = this.apiUrl + "/core/submit_form_application";
        return this.http.post(postUrl, credential).catch(this.handleError);
    };
    RestService.prototype.getPersonForPayment = function () {
        var getPersoneUrl = this.apiUrl2 + "/core/get_person?version=2";
        return this.http.get(getPersoneUrl);
    };
    RestService.prototype.getCountCart = function () {
        var url = this.apiUrl + "/core/count_cart/";
        return this.http.get(url);
    };
    RestService.prototype.deletePerson = function (credential) {
        var data = { application_id: credential };
        var postUrl = this.apiUrl + "/core/delete_person";
        return this.http.post(postUrl, data);
    };
    RestService.prototype.checkExistData = function (token) {
        var data = { token: token };
        var getPersoneUrl = this.apiUrl + "/core/check_person/";
        return this.http.post(getPersoneUrl, data);
    };
    RestService.prototype.getContactSubject = function () {
        var url = this.apiUrl + "/component/getContactSubject";
        return this.http.get(url).catch(this.handleError);
    };
    RestService.prototype.getCountrySubject = function () {
        var url = this.apiUrl + "/component/getCountrySubject";
        return this.http.get(url).catch(this.handleError);
    };
    // getTermCondition
    RestService.prototype.getTermConditions = function (language) {
        var url = this.apiUrl + "/component/getTermConditions/" + language;
        return this.http.get(url).catch(this.handleError);
    };
    // Language
    RestService.prototype.getLanguage = function () {
        var url = this.apiUrl + "/component/language";
        return this.http.get(url);
    };
    // check status
    RestService.prototype.checkStatus = function (data) {
        var url = this.apiUrl + "/check_status/status_enquiry";
        return this.http.post(url, data);
    };
    RestService.prototype.retrieveApplication = function (data) {
        var url = this.apiUrl + "/retrieve_application/send_application_id";
        return this.http.post(url, data);
    };
    RestService.prototype.uploadHoteldoc = function (uploadData) {
        return this.http.post(this.uploadHoteldocUrl, uploadData);
    };
    RestService.prototype.uploadFlightdoc = function (uploadData) {
        var uploadFlightdocUrl = this.apiUrl + "/upload_flight_file";
        return this.http.post(uploadFlightdocUrl, uploadData);
    };
    RestService.prototype.uploadThirdVisadoc = function (uploadData) {
        var url = this.apiUrl + "/upload_third_visa_file";
        return this.http.post(url, uploadData);
    };
    RestService.prototype.uploadGuarantordoc = function (uploadData) {
        var url = this.apiUrl + "/upload_guarantor_file";
        return this.http.post(url, uploadData);
    };
    RestService.prototype.uploadBankStatementdoc = function (uploadData) {
        var url = this.apiUrl + "/upload_bank_statement_file";
        return this.http.post(url, uploadData);
    };
    // Dealer
    RestService.prototype.dealerLogin = function (data) {
        var url = this.apiUrl + "/dealer/login";
        return this.http.post(url, data);
    };
    // Article
    RestService.prototype.getArticle = function () {
        var url = this.apiUrl + "/component/article_list";
        return this.http.get(url);
    };
    RestService.prototype.getArticleDetail = function (title, lang) {
        var url = this.apiUrl + "/component/article_detail/" + title + "/" + lang;
        return this.http.get(url);
    };
    RestService.prototype.getUserRejectedById = function (data) {
        var url = this.apiUrl + "/re_apply/getStatus";
        return this.http.post(url, data);
    };
    RestService.prototype.getApplicationInfo = function (application_id) {
        var data = { application_id: application_id };
        var authInfo = JSON.parse(this.localStorage.getItem(environment.localAuthenInfo));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        var url = this.apiUrl + "/re_apply/getApplicationInfo";
        return this.http.post(url, data, { headers: this.headers });
    };
    RestService.prototype.checkPayment = function (invoice_id) {
        var url = this.apiUrl + "/payment/check_payment";
        return this.http.post(url, invoice_id);
    };
    RestService.prototype.checkRePay = function (invoice_id) {
        var url = this.apiUrl + "/payment/check_repay";
        return this.http.post(url, invoice_id);
    };
    RestService.prototype.payNow = function (invoice_id) {
        var url = this.apiUrl + "/payment/paynow";
        return this.http.post(url, invoice_id);
    };
    RestService.prototype.RePayNow = function (invoice_id) {
        var url = this.apiUrl + "/payment/repay";
        return this.http.post(url, invoice_id);
    };
    // Info page
    RestService.prototype.getInfo = function (lang) {
        var url = this.apiUrl + "/component/info/" + lang;
        return this.http.get(url);
    };
    // About page
    RestService.prototype.getAbout = function (lang) {
        var url = this.apiUrl + "/component/about/" + lang;
        return this.http.get(url);
    };
    RestService.prototype.getPaymentMethods = function () {
        var url = this.apiUrl + "/component/payment_method";
        return this.http.get(url);
    };
    RestService.prototype.save_partner_access = function (partner_id) {
        var data = { partner_id: partner_id };
        var postUrl = this.apiUrl + "/partner/save_access";
        return this.http.post(postUrl, data).catch(this.handleError);
    };
    // Partner
    RestService.prototype.partnerLogin = function (data) {
        var url = this.apiUrl + "/partner/login";
        return this.http.post(url, data);
    };
    RestService.prototype.getAllData = function (partner_id) {
        var data = { partner_id: partner_id };
        var authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        var url = this.apiUrl + "/partner/getData";
        return this.http.post(url, data, { headers: this.headers });
    };
    RestService.prototype.getAllVisit = function (partner_id) {
        var data = { partner_id: partner_id };
        var authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        var url = this.apiUrl + "/partner/getVisit";
        return this.http.post(url, data, { headers: this.headers });
    };
    RestService.prototype.isLoggedIn = function () {
        var authenInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        return authenInfo != null;
    };
    RestService.prototype.getExceptMessage = function () {
        var url = this.apiUrl + "/component/except_message";
        return this.http.get(url);
    };
    // Download pdf
    RestService.prototype.downloadPDF = function (data) {
        var url = this.apiUrl2 + "/approval-letter";
        return this.http.post(url, data, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json"),
        });
    };
    RestService.prototype.newDownloadPDF = function (data) {
        var url = this.apiUrl + "/download-approval";
        return this.http.post(url, data, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json"),
        });
    };
    // Calculate price
    RestService.prototype.getCalculatePrice = function (country_id) {
        var data = { country_id: country_id };
        var getPriceUrl = this.apiUrl + "/component/price_calculate";
        return this.http.post(getPriceUrl, data);
    };
    // get status can apply
    RestService.prototype.getIsCloseApply = function () {
        var url = this.apiUrl + "/component/close_apply_status";
        return this.http.get(url);
    };
    RestService.prototype.login = function (form) {
        var url = this.apiUrl + "/secure/login";
        return this.http.post(url, form);
    };
    RestService.prototype.CheckDuplicate = function (form) {
        var url = this.apiUrl2 + "/check-duplicate";
        return this.http.post(url, form);
    };
    RestService.prototype.CheckArrivalDate = function (form) {
        var url = this.apiUrl2 + "/check-arrival";
        return this.http.post(url, form);
    };
    RestService.prototype.newCheckDuplicate = function (form) {
        var url = this.apiUrl + "/check-duplicate";
        return this.http.post(url, form);
    };
    RestService.prototype.newCheckArrivalDate = function (form) {
        var url = this.apiUrl + "/check-arrival";
        return this.http.post(url, form);
    };
    RestService.prototype.getFAQGroup = function (language) {
        var url = this.apiUrl2 + "/component/getFAQ/" + language;
        return this.http.get(url).catch(this.handleError);
    };
    RestService.prototype.ocrMRZDetection = function (image) {
        return this.http.post(this.ocrMRZUrl, image);
    };
    RestService.prototype.uploadProofFileService = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.apiUrl2 + "/prooffile_upload";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.http.post(url, data).toPromise()];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RestService.prototype.addUserSession = function (session_id) {
        var data = {
            session_id: session_id,
        };
        var url = this.apiUrl2 + "/user_session";
        return this.http.post(url, data);
    };
    RestService.prototype.updateUserSession = function (data) {
        var url = this.apiUrl2 + "/update/user_session";
        return this.http.post(url, data);
    };
    RestService.prototype.continueReviewService = function (data) {
        return this.http.post(this.apiUrl2 + "/continue-review", data);
    };
    RestService.prototype.payNowV2Service = function (data) {
        var url = this.paymentUrl + "/payment/paynow";
        return this.http.post(url, data);
    };
    RestService.prototype.newBcelService = function (data) {
        return this.http.post(this.paymentUrl + "/payment/bcel", data);
    };
    RestService.prototype.getBase64ImageService = function (imageName, image) {
        var data = {};
        data[image + "_name"] = imageName;
        return this.http.post(this.apiUrl2 + "/get_" + image, data);
    };
    RestService.prototype.getMaintainance = function () {
        return this.http.get(this.apiUrl2 + "/maintainance");
    };
    RestService.prototype.verifyCaptChaService = function (token) {
        var data = {
            token: token,
        };
        return this.http.post(this.apiVerifyCaptchaUrl + "/verify-turnstile", data);
    };
    RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.LOCAL_STORAGE), i0.ɵɵinject(i2.HttpClient)); }, token: RestService, providedIn: "root" });
    return RestService;
}());
export { RestService };
